import React, {createContext, useState} from 'react';
import ApiService from '../services/ApiService';

const ApiContext = createContext();

export const ApiProvider = ({ children }) => {
  const apiService = new ApiService("");
  const [globalUser, setGlobalUser] = useState(null);
  const [responseAcessCode, setResponseAcessCode] = useState({});

  return (
    <ApiContext.Provider value={{ globalUser, setGlobalUser, apiService, setResponseAcessCode, responseAcessCode }}>
      {children}
    </ApiContext.Provider>
  );
};

export default ApiContext;